(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-adhoc-loader/assets/javascripts/player-adhoc-loader.js') >= 0) return;  svs.modules.push('/components/accountservices/player-adhoc-loader/assets/javascripts/player-adhoc-loader.js');
"use strict";

(function (svs) {
  svs.accountservices = svs.accountservices || {};
  svs.accountservices.player_adhoc_loader = svs.accountservices.player_adhoc_loader || {};
  const logger = svs.core.log.getLogger('accountservices:player_adhoc_loader');

  if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    logger.debug('Not logged in, removed cached data from local storage for playscan');
    svs.components.Storage.browser.remove('custData', 'playscan');
  }
  const isShowCookieDialog = Boolean(svs.utils.paramHandler.get({
    key: 'showCookieDialog'
  }).value);
  if (isShowCookieDialog) {
    svs.utils.paramHandler.remove({
      key: 'showCookieDialog'
    });
    document.addEventListener('DOMContentLoaded', () => {
      setTimeout(() => {
        window.OneTrust && window.OneTrust.IsAlertBoxClosedAndValid() && window.OneTrust.ToggleInfoDisplay();
      }, 1000);
    });
  }
})(svs);

 })(window);